import { Typography } from "@atoms";
import { Box } from "@mui/material";
import { styled } from "@mui/material/styles";

export const FlexWrapper = styled(Box)(() => ({
  display: "flex",
  flexDirection: "column",
}));

export const Wrap = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "relative",
  height: "32px",
  ".react-datepicker": {
    width: "283px",
    paddingBottom: theme.spacing(2.5),
    fontFamily: theme.typography.fontFamily,
    border: "none",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.2)",
  },
  ".react-datepicker__month-container": {
    float: "none",
  },
  ".react-datepicker__day, .react-datepicker__time-name": {
    fontSize: "14px",
    color: theme.palette.black,
    width: "30px",
    height: "30px",
    lineHeight: "30px",
    margin: `${theme.spacing(1)} 0 0`,
  },
  ".react-datepicker__day": {
    marginBottom: `${theme.spacing(0.5)} !important`,
    "&:hover": {
      backgroundColor: theme.palette.darkGreen,
      color: theme.palette.common.white,
    },
    "&:focus": {
      outline: "none",
    },
    "&.react-datepicker__day--outside-month": {
      color: theme.palette.gray[500],
    },
  },
  ".react-datepicker__day--selected, .react-datepicker__day--range-end": {
    color: `${theme.palette.common.white} !important`,
    backgroundColor: theme.palette.darkGreen,
    span: {
      display: "inline-block",
      width: "30px",
      height: "30px",
      backgroundColor: `${theme.palette.darkGreen} !important`,
    },
  },
  ".react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range":
    {
      backgroundColor: theme.palette.darkGreen,
    },
  ".react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover, .react-datepicker__year-text:hover":
    {
      borderRadius: 0,
    },
  ".react-datepicker__day:hover": {
    color: theme.palette.common.white,
  },
  ".react-datepicker__header": {
    backgroundColor: theme.palette.common.white,
    border: "none",
    padding: 0,
    marginBottom: theme.spacing(3),
  },
  ".react-datepicker__day-name": {
    color: "rgba(0, 0, 0, 0.5)",
    fontSize: "12px",
    width: "30px",
    height: "13px",
  },
  ".react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name":
    {
      margin: 0,
    },
  ".react-datepicker__day-names": {
    padding: `0 ${theme.spacing(4)}`,
    margin: 0,
  },
  ".react-datepicker__month": {
    margin: 0,
    padding: `0 ${theme.spacing(2)}`,
  },
  ".react-datepicker__triangle": {
    display: "none",
  },
  ".react-datepicker__current-month": {
    paddingTop: theme.spacing(4),
    fontSize: "14px",
    fontWeight: 600,
    marginBottom: theme.spacing(3.5),
  },
  ".react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__quarter-text--keyboard-selected, .react-datepicker__year-text--keyboard-selected":
    {
      backgroundColor: "transparent",
      borderRadius: 0,
    },
  ".react-datepicker__day--keyboard-selected.react-datepicker__day--in-range": {
    backgroundColor: theme.palette.darkGreen,
  },
  ".react-datepicker__navigation--previous": {
    left: "30px",
  },
  ".react-datepicker__navigation--next": {
    right: "30px",
  },
  ".react-datepicker__navigation": {
    top: theme.spacing(4.5),
  },
  ".react-datepicker__navigation-icon": {
    top: theme.spacing(-1),
    "&:before": {
      borderColor: theme.palette.black,
      borderWidth: "1px 1px 0 0",
    },
  },
  ".react-datepicker-popper[data-placement^=bottom]": {
    paddingTop: theme.spacing(1),
    zIndex: 5,
  },
  ".react-datepicker-popper[data-placement^=top-start]": {
    zIndex: 6,
  },
  "& svg ": {
    position: "absolute",
    right: 12,
    "& > path": {
      fill: theme.palette.black,
    },
  },
}));

export const CustomInputWrap = styled(Box)(({ theme, error, customWidth }) => ({
  display: "flex",
  alignItems: "center",
  height: "32px",
  borderRadius: "6px",
  border: `1px solid ${error ? theme.palette.red : theme.palette.gray[200]}`,
  padding: theme.spacing(2, 3),
  minWidth: customWidth,
  width: "100%",
}));

export const Placeholder = styled(Typography)(() => ({
  color: "rgba(21, 21, 21, 0.5)",
}));
