import { useFieldArray, useFormContext } from "react-hook-form";
import { arrayOf, bool, func, number, shape, string } from "prop-types";
import { Box, Button, Typography } from "@mui/material";

import {
  DatePickerField,
  ImageUpload,
  NestedSingleSelect,
  RadioButtonsGroup,
  RemoveButton,
  TextFieldWithLimit,
} from "@molecules";
import {
  ControlledTextField,
  Dropdown,
  Subtitle,
  TimePickerField,
  Toggle,
  RichTextEditorWithLimit,
} from "@atoms";
import { palette } from "@styles/palette";

import { Container, GapWrapper, Wrap } from "./styles";
import {
  currencyOptions,
  durationOptions,
  emptyService,
  eventTypeOptions,
  frequencyOptions,
} from "./utils";

const EventDetails = ({
  locationOptions,
  handleSearchLocations,
  isStartDateChangeable,
}) => {
  const {
    formState: { errors },
    control,
    getValues,
    watch,
  } = useFormContext();

  const selectedEventType = watch("date.eventType");
  const isTimeSlotted = watch("isTimeSlotted");
  const location = watch("location");
  const startDate = watch("date.startDate");
  const endDate = watch("date.endDate");
  const startTime = watch("startTime");
  const endTime = watch("endTime");

  const {
    fields: services,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: "services",
  });

  const onAddSevice = () => append(emptyService);
  const onRemoveService = (
    { action, name, duration, price, currency },
    index,
    serverId
  ) => {
    if (action === "create") {
      remove(index);
    } else {
      update(index, {
        id: serverId,
        name,
        duration,
        price,
        currency,
        action: "delete",
      });
    }
  };

  return (
    <Wrap>
      <Container>
        <Box sx={{ mb: "30px", width: "438px" }}>
          <TextFieldWithLimit
            label="Event Name*"
            name="name"
            placeholder="Event Name"
            charactersLimit={100}
            sx={{ mb: "20px" }}
            error={!!errors?.name?.message}
          />
          <RichTextEditorWithLimit
            name="description"
            label="Description of event*"
            error={!!errors?.description?.message}
            charactersLimit={500}
          />
          <Box sx={{ display: "flex", gap: "20px", mt: "20px" }}>
            <NestedSingleSelect
              id="location-nested-select"
              options={locationOptions}
              option={location}
              name="location"
              customWidth="240px"
              label="Location*"
              onSearchChange={handleSearchLocations}
              textFieldProps={{
                error: !!errors?.location?.name?.message,
              }}
            />
            <Box>
              <TextFieldWithLimit
                label="Location details"
                name="locationDetails"
                placeholder="Room number, etc.."
                charactersLimit={30}
                sx={{ maxWidth: "180px" }}
              />
            </Box>
          </Box>
        </Box>
        <ImageUpload
          imageURL={getValues("image")}
          fieldName="image"
          title="Event Image*"
          tooltipText="Meaningful event image, no more than 10MB"
          errorText={errors?.image?.message}
          customHeight="200px"
        />
      </Container>
      <Subtitle>Time & Duration</Subtitle>
      <Box sx={{ mb: "20px" }}>
        <RadioButtonsGroup name="date.eventType" options={eventTypeOptions} />
      </Box>
      {selectedEventType === "recurring" && (
        <Typography sx={{ fontWeight: "600", mt: "20px", mb: "10px" }}>
          Date
        </Typography>
      )}
      <GapWrapper
        sx={{
          maxWidth: `${selectedEventType === "recurring" ? "490px" : "140px"}`,
        }}
      >
        <DatePickerField
          disabled={!isStartDateChangeable}
          label={selectedEventType === "recurring" ? "Start*" : "Date*"}
          name="date.startDate"
          selectsStart
          startDate={new Date(startDate)}
          endDate={new Date(endDate)}
          minDate={new Date()}
          maxDate={new Date(endDate)}
          errorText={errors?.date?.startDate?.message}
        />
        {selectedEventType === "recurring" && (
          <Dropdown
            name="frequency"
            id="frequency"
            options={frequencyOptions}
            label="Frequency"
            sx={{ width: "140px" }}
          />
        )}
        {selectedEventType === "recurring" && (
          <DatePickerField
            label="End*"
            name="date.endDate"
            selectsEnd
            startDate={new Date(startDate)}
            endDate={new Date(endDate)}
            minDate={new Date(startDate)}
            errorText={errors?.date?.endDate?.message}
            disabled={!startDate}
          />
        )}
      </GapWrapper>
      <Typography sx={{ fontWeight: "600", mt: "20px", mb: "10px" }}>
        Time
      </Typography>
      <GapWrapper sx={{ mb: "26px", alignItems: "start" }}>
        <TimePickerField
          id="start-time"
          label="Start*"
          name="startTime"
          errorText={errors?.startTime?.message}
          maxTime={endTime}
        />
        <TimePickerField
          id="end-time"
          label="End*"
          name="endTime"
          errorText={errors?.endTime?.message}
          minTime={startTime}
        />
      </GapWrapper>
      <Box sx={{ mb: "20px" }}>
        <Toggle
          name="isTimeSlotted"
          id="time-slotted-toggle"
          label="Time Slotted"
        />
      </Box>
      {isTimeSlotted ? (
        <>
          <Subtitle>Services Offered</Subtitle>
          {services.map((service, index) => {
            if (service.action !== "delete") {
              return (
                <GapWrapper key={service.id} sx={{ mb: "14px" }}>
                  <Box>
                    <TextFieldWithLimit
                      label="Name*"
                      name={`services.${index}.name`}
                      placeholder="Service name"
                      charactersLimit={40}
                      error={!!errors?.services?.[index]?.name?.message}
                    />
                  </Box>
                  <Dropdown
                    name={`services.${index}.duration`}
                    id="duration"
                    options={durationOptions}
                    label="Duration*"
                    sx={{ width: "160px" }}
                  />
                  <ControlledTextField
                    type="number"
                    label="Price"
                    name={`services.${index}.price`}
                    error={errors?.services?.[index]?.price?.message}
                    titleMargin="8px"
                    sx={{ maxWidth: "80px" }}
                  />
                  <Dropdown
                    name={`services.${index}.currency`}
                    id="currency"
                    options={currencyOptions}
                    sx={{ mt: "27px", width: "75px" }}
                  />
                  {services.filter(({ action }) => action !== "delete")
                    ?.length > 1 && (
                    <RemoveButton
                      onClick={() =>
                        onRemoveService(
                          service,
                          index,
                          getValues(`services.${index}.id`)
                        )
                      }
                      sx={{
                        alignSelf: "end",
                        color: `${palette.gray[200]} !important`,
                        fontWeight: "400 !important",
                      }}
                    >
                      Remove
                    </RemoveButton>
                  )}
                </GapWrapper>
              );
            }
          })}
          <Button variant="outlined" onClick={onAddSevice}>
            + Add Service
          </Button>
        </>
      ) : (
        <ControlledTextField
          sx={{ maxWidth: "100px" }}
          type="number"
          label="Capacity Limit"
          name="capacityLimit"
          placeholder="--"
        />
      )}
    </Wrap>
  );
};

EventDetails.propTypes = {
  isStartDateChangeable: bool,
  locationOptions: arrayOf(
    shape({
      id: string.isRequired,
      name: string.isRequired,
      suboptions: arrayOf(
        shape({
          id: string.isRequired,
          name: string.isRequired,
          suboptions: arrayOf(
            shape({
              id: number.isRequired,
              name: string.isRequired,
              timezone: string.isRequired,
            }).isRequired
          ).isRequired,
        }).isRequired
      ).isRequired,
    }).isRequired
  ),
  handleSearchLocations: func.isRequired,
};

EventDetails.defaultProps = {
  isStartDateChangeable: true,
  locationOptions: [],
};

export default EventDetails;
